import React from "react"
import { CarouselProvider, Slider, Slide } from "pure-react-carousel"
import { SocialProofData, SocialProofConfig } from "../../config/config-data"


const SocialProof = ({ GradientAnimation }) => {

  return(
    <section id={SocialProofConfig.section_header}>
      {SocialProofConfig.section_header &&
      <div id="socialproof_1662793377494" className={`Heading_Text ${!GradientAnimation ? "Heading_Gradient" : ""}`}>{SocialProofConfig.section_header}</div>
      }
      {SocialProofConfig.section_sub_header &&
      <div id="socialproof_1662793381174" className="Normal_Text" style={{paddingBottom:"var(--Text_Size_Five)"}}>{SocialProofConfig.section_sub_header}</div>
      }
      <CarouselProvider
        isIntrinsicHeight={true}
        infinite={true}
        isPlaying={GradientAnimation ? false : true}
        interval={SocialProofConfig.interval_of_slides}
        totalSlides={SocialProofConfig.number_of_slides}
        visibleSlides={SocialProofConfig.visible_of_slides}
      >
        <Slider>
          {SocialProofData.map(({ social_proof_image, social_proof_image_height, social_proof_image_width, social_proof_image_stretch_width, social_proof_heading, social_proof_text, social_proof_author }) => (
            <Slide key={social_proof_text}>
              <div id={"socialproof_"+social_proof_text} className="Card">
                {social_proof_image &&
                <div>
                  <img src={social_proof_image} style={{borderRadius:"2vmin", width:`${social_proof_image_stretch_width}`, height:"auto",}} height={social_proof_image_height} width={social_proof_image_width} loading="lazy" alt="Image" />
                </div>
                }
                <div className="Card_Container">
                  {social_proof_heading &&
                  <div className="Sub_Heading_Text">{social_proof_heading}</div>
                  }
                  {social_proof_text &&
                  <div className="Normal_Text">
                    <span style={{color: "var(--SecondaryBrand)", fontSize: "var(--Text_Size_Three)"}}>&#10077; </span>
                      {social_proof_text}
                    <span style={{color: "var(--SecondaryBrand)", fontSize: "var(--Text_Size_Three)"}}> &#10078;</span>
                  </div>
                  }
                  {social_proof_author &&
                  <div className="Author_Text">
                    <i>According to:</i>
                    {social_proof_author}
                  </div>
                  }
                </div>
              </div>
            </Slide>
          ))}
        </Slider>
      </CarouselProvider>
    </section>
  );
};

export default SocialProof